@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Rajdhani:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  margin: 0;
}

.inter{
  font-family: "Roboto";
}

.rajdhani{
  font-family: "Rajdhani", sans-serif;
}

.noto{
  font-family: "Noto Sans";
}

.fontColour1{
  color: #042338;
}


.footerColour{
  background-color: #8CA3A0;
}

.whyUsNumberColour{
  fill: white;
  stroke: #8CA3A0;
  stroke-width: 2px;
}


.background-colour{
  background-color: #FAFEFF
}


.inter{
  font-family: "Inter";
}


.link {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  transition: transform 0.3s ease; 
}

.link:hover {
  transform: translateX(20px);
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 2s ease forwards; 
}


.menuLineHeight{
  height: 0.080rem;
}

.stroke {
  color: white; /* Change to your desired text color */
  text-shadow: 
    -1px -1px 0 rgba(140, 163, 160, 0.85),  
     1px -1px 0 rgba(140, 163, 160, 0.85),  
    -1px  1px 0 rgba(140, 163, 160, 0.85),  
     1px  1px 0 rgba(140, 163, 160, 0.85); /* Adjust shadow color as needed */
}


.transition-all {
  transition: all 0.5s ease;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.navbar-transition {
  transition: background-color 0.3s ease;
}


.button {
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
}

.button span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 15px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}